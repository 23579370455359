<template>
  <div class="activity-child-detail manage-wrapper">
    <!-- <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item :to="{ path: '/item-list' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'activity' }">活动管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{activityInfo.name || '--'}}</el-breadcrumb-item>
    </el-breadcrumb> -->
    <div class="child-container">
      <!-- 基础信息 -->
      <div class="activity-info-wrapper flex-layout">
        <div class="cover-wrapper">
          <img :src="downloadURL + activityInfo.cover" alt="" class="activity-cover"
            :onerror="$store.state.course.defaultActivityCoverStudyWall">
          <p class="default-tilel" v-if="!activityInfo.cover">主题活动</p>
        </div>
        <div class="activity-info">
          <h3 class="activity-title">{{activityInfo.name || '无'}}</h3>
          <p class="activity-info-item"><strong>所属项目</strong>：{{currentProject.name || '无'}}</p>
          <p class="activity-info-item"><strong>活动时间</strong>：{{activityInfo.activityBeginTime}} ——
            {{activityInfo.activityEndTime}}</p>
          <p class="activity-info-item last-item wes-3">
            <strong>活动简介</strong>：{{activityInfo.introduction || '暂无简介'}}</p>
          <button class="to-rule csp"
            @click="$router.push(`/activity/rule/${$route.params.id}?breadNumber=5`)">活动规则</button>
        </div>
      </div>
      <!-- 筛选条件 -->
      <div class="group-wrapper">
        <el-tabs v-model="currentTab" :stretch="true" :before-leave="changeTab">
          <el-tab-pane :label="tab.name" :name="tab.title" v-for="tab in tabList" :key="tab.title" lazy></el-tab-pane>
        </el-tabs>
        <component class="tab" v-bind:is="currentTabComponent" :activityType="activityInfo.activityType"
          :creatChild="showAddDialog" @open="showAddDialog = false">
        </component>
        <div v-if="currentTab == 'list' && currentActivityType" class="add csp" @click="showAddDialog = true">
          <i class="el-icon-circle-plus-outline"></i>
          <span>添加子活动</span>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  import wallList from "./components/wall-list.vue"
  import wallMember from "./components/wall-member.vue"
  import wallData from "./components/wall-data.vue"
  export default {
    components: {
      'wall-data': wallData,
      'wall-member': wallMember,
      'wall-list': wallList,
    },
    data() {
      return {
        activityInfo: {},
        currentTab: 'list',
        tabList: [{
            title: "list",
            name: '子活动'
          },
          {
            title: "member",
            name: '活动成员'
          },
          {
            title: "data",
            name: '活动数据'
          },
        ],
        showAddDialog: false,
      }
    },
    computed: {
      ...mapState({
        currentProject: state => state.user.currentProject,
        currentActivityType: state => state.course.currentActivityType,
      }),
      currentTabComponent: function () {
        return "wall-" + this.currentTab;
      }
    },
    beforeRouteEnter(to, from, next) {
      // console.log(from);
      next(vm => {
        if (from.name != 'ActivityChildData') {
          vm.currentTab = 'list';
        }
      });
    },
    methods: {
      changeTab(tabTitle) {
        // if (tabTitle == 'data') {
        //   this.$notify.info('该功能暂未开放');
        //   return false;
        // }
        this.currentTab = tabTitle
      },
      dialogVisible(data) {
        this.showAddDialog = false;
      },
      async getActivityMember() {
        let params = {
          id: this.$route.params.id,
          page: 1,
          limit: 1,
        }
        let resData = await this.$Api.Activity.getActivityMember(params);
        // console.log(resData);
        this.tabList[1].name = `活动成员(${resData.data.total})`
      },
      getMemberTotal(data) {
        // console.log(data);
        this.tabList[1].name = `活动成员(${data})`
      },
      async getActivityInfo() {
        let resData = await this.$Api.Activity.getActivityDetail(this.$route.params.id)
        // console.log('活动信息', resData);
        this.activityInfo = resData.data
      },
    },
    // mounted() {
    //   this.getActivityInfo();
    //   this.getActivityMember();
    // },
    activated() {
      this.getActivityInfo();
      this.getActivityMember();
    }
  }
</script>

<style lang="less" scoped>
  @font-face {
    font-family: 'ktf';
    src: url("../../../../static/font/ktf.TTF");
  }

  .activity-child-detail {
    .activity-info-wrapper {
      margin-bottom: 19px;
      border: 1px solid #f2f2f2;
      border-radius: 10px;
      padding: 38px 27px 40px;
      background-color: #fff;
      line-height: 1;

      .activity-info {
        overflow: hidden;
        flex: 1;
        position: relative;
        line-height: 20px;
      }

      .cover-wrapper {
        position: relative;

        .default-tilel {
          font-family: 'ktf';
          position: absolute;
          color: #fff;
          font-size: 34px;
          top: 38%;
          left: 45px;
          z-index: 99;
        }
      }

      .activity-cover {
        margin-right: 36px;
        width: 361px;
        height: 215px;
        flex-shrink: 0;
        border-radius: 10px;
      }

      .activity-title {
        font-size: 13px;
        font-weight: 600;
        margin: 12px 0 19px;
      }

      .activity-info-item {
        color: #999;
        margin-bottom: 19px;
        font-size: 12px;

        &.last-item {
          margin-bottom: 33px;

        }

        strong {
          font-weight: 600;
        }
      }

      .to-rule {
        border-radius: 5px;
        padding: 0 20px;
        line-height: 27px;
        color: #fff;
        letter-spacing: 1px;
        font-size: 12px;
        background-color: #508EF9;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .group-wrapper {
      margin-bottom: 19px;
      box-sizing: border-box;
      padding: 0 27px 0;
      overflow-y: auto;
      border: 1px solid #f2f2f2;
      border-radius: 8px;
      background-color: #fff;
      position: relative;

      .add {
        position: absolute;
        top: 14px;
        right: 27px;
        z-index: 5;
        font-size: 12px;
        color: #999999;

        .el-icon-circle-plus-outline {
          font-size: 16px;
          margin-right: 6px;
          vertical-align: text-bottom;
        }
      }


      /deep/ .el-tabs__header {
        margin-bottom: 11px !important;

        .el-tabs__item {
          padding: 0 20px !important;
          font-size: 13px;
          color: #555555;
          height: 44px;
          line-height: 44px;

        }

        .el-tabs__nav-wrap.is-top {
          padding: 0 13px;
        }

        .el-tabs__item:hover,
        .el-tabs__item.is-active {
          color: #508EF9;
        }
      }

      /deep/ .el-tabs__active-bar {
        height: 1px;
        background-color: #508EF9;
        padding: 0 20px;
        margin-left: -20px;
      }

      /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
        background-color: #f2f2f2;
      }

      /deep/ .el-tabs__nav.is-stretch>* {
        flex: 0 0 15%;
      }


    }

    /deep/ .el-dialog {
      border-radius: 10px;

      .el-dialog__header {
        border-bottom: 1px solid #E4E4E4;
        padding: 15px 31px 10px;
      }

      .el-dialog__title {
        font-size: 15px;
        color: #333;

        &::before {
          content: '';
          width: 5px;
          height: 17px;
          background-color: #01A2FD;
          display: inline-block;
          margin-right: 14px;
          vertical-align: -3px;
          border-radius: 3px;
        }
      }

      .el-dialog__headerbtn {
        top: 15px;
      }

      .btns-box {
        text-align: center;
      }

      .createAddress {
        background: linear-gradient(180deg, #5DA7FF 0%, #4893F9 100%);
        color: #fff;
        padding: 6px 15px;
        border: 0;
        margin-left: 15px;

        &:hover {
          background: linear-gradient(180deg, rgba(93, 167, 255, .8) 0%, rgba(72, 147, 249, .8) 100%);
        }
      }

      .tips {
        display: inline-block;
        font-size: 20px;
        margin-left: 10px;
        line-height: 28px;
        position: relative;

        &:hover {
          color: #999;
        }

        .tips-txt {
          font-size: 12px;
          color: #333;
          position: absolute;
          top: 30px;
          left: 0;
          z-index: 999;
          width: 300px;
          padding: 10px 15px;
          background-color: #fff;
          border-radius: 10px;
          border: 1px solid #E4E4E4;
        }

        .tips-img {
          width: 300px;
        }

      }

      .submit {
        background: linear-gradient(180deg, #5DA7FF 0%, #4893F9 100%);
        border-radius: 25px;
        color: #fff;
        line-height: 35px;
        padding: 0 63px;
        cursor: pointer;
        border: 0;

        &:hover {
          background: linear-gradient(180deg, rgba(93, 167, 255, .8) 0%, rgba(72, 147, 249, .8) 100%);
        }
      }
    }


    @media screen and (min-width:1250px) {
      .activity-info-wrapper {
        margin-bottom: 23px;
        padding: 46px 33px 49px;

        .activity-info {
          line-height: 24px;
        }

        .cover-wrapper {
          .default-tilel {
            font-size: 42px;
            top: 38%;
            left: 50px;
          }
        }

        .activity-cover {
          margin-right: 43px;
          width: 437px;
          height: 261px;
        }

        .activity-title {
          font-size: 16px;
          margin: 14px 0 23px;
        }

        .activity-info-item {
          margin-bottom: 23px;

          &.last-item {
            margin-bottom: 39px;

          }
        }

        .to-rule {
          padding: 0 24px;
          line-height: 34px;
          font-size: 15px;
        }
      }

      .group-wrapper {
        margin-bottom: 23px;
        padding: 0 33px 0;

        .add {
          position: absolute;
          top: 16px;
          right: 33px;
          font-size: 14px;

          .el-icon-circle-plus-outline {
            font-size: 19px;
          }
        }


        /deep/ .el-tabs__header {

          .el-tabs__item {
            font-size: 16px;
            height: 53px;
            line-height: 53px;

          }
        }
      }
    }
  }
</style>