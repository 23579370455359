<template>
  <div class="child-data">
    <div class="top-container flex-between">
      <div class="avg-join">
        <div class="avg-join-pie" id="avg-join-pie"></div>
      </div>
      <ul class="data-list flex-align-between">
        <li v-for="(item,index) in countMap" :key="index" class="data-list-li">
          <img :src="item.icon" alt="" class="data-icon">
          <p class="data-num">{{item.num}}<span class="unit"><i v-if="item.name=='发帖/回复数'" class="reply">/
                {{item.reply}}</i>{{item.unit}}</span></p>
          <p class="data-name">{{item.name}}</p>
        </li>
      </ul>
    </div>
    <div class="bottom-container">
      <ul class="child-data-list flex-layout">
        <li v-for="(item,index) in dataList" :key="index" class="list-item csp"
          @click="$router.push(`/activity/child/data/${item.id}?breadNumber=5`)">
          <!-- <img :src="downloadURL + item.cover" :onerror="$store.state.course[`defaultTaskCover`]" alt
            class="item-img" /> -->
          <div class="cover-wrapper">
            <img src="@/assets/images/active/datacover.png" alt class="item-img" />
            <p class="default-tilel">子活动</p>
          </div>
          <p class="item-name wes-2">{{item.name}}</p>
          <div class="info">
            <p>参与度：{{((item.activityMemberCount/item.memberCount)*100).toFixed(2)}}%</p>
            <p>参与人数：{{item.activityMemberCount}}人</p>
            <p>发帖数：{{item.postCount}}次</p>
            <p>回复数：{{item.replyCount}}次</p>
            <p>作品数：{{item.workCount}}个</p>
          </div>
        </li>
      </ul>
      
      <Empty :show="pages.total <= 0"></Empty>
      <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
        :limit.sync="pages.pageSize" :pageSizes="[3,6,9]" @pagination="handlePageChange">
      </pagination>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        pages: { //分页
          currentPage: 1,
          pageSize: 6,
          total: 0,
        },
        countMap: [{
            title: 'activityMemberCount',
            name: '参与人数',
            num: 10,
            unit: '人',
            icon: require("@/assets/images/active/data/data-icon1.png")
          },
          {
            title: 'activityCount',
            name: '子活动',
            num: 10,
            unit: '个',
            icon: require("@/assets/images/active/data/data-icon2.png")
          },
          {
            title: 'workCount',
            name: '作品数',
            num: 210,
            unit: '个',
            icon: require("@/assets/images/active/data/data-icon3.png")
          },
          {
            title: 'postCount',
            name: '发帖/回复数',
            num: 150,
            reply: 236,
            unit: '次',
            icon: require("@/assets/images/active/data/data-icon4.png")
          },
          {
            title: 'remainingDays',
            name: '活动剩余时间',
            num: 30,
            unit: '天',
            icon: require("@/assets/images/active/data/data-icon5.png")
          }
        ],
        dataList: [{}], //子活动列表
        activityData: {}, //接口取来的活动数据
      }
    },
    methods: {
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        this.getActivityData();
      },
      async getActivityData() {
        let params = {
          id: this.$route.params.id,
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
        }
        let resData = await this.$Api.Activity.getActivityData(params);
        // console.log(resData.data);
        this.activityData = resData.data;
        this.dataList = resData.data.subTeachActivityCounts;
        this.pages.total = resData.data.total;
        this.countMap.map(item => {
          // console.log(resData.data[item.title])
          item.num = resData.data[item.title]
          if (item.title == 'postCount') item.reply = resData.data.replyCount
          if (item.title == 'activityCount') item.num = resData.data.subTeachActivityCounts.length
        })
        this.initEchart();
      },
      initEchart() {
        console.log(this.activityData);
        let myChart = this.$echarts.init(document.getElementById("avg-join-pie"));
        // console.log(myChart)
        let submitRate = 10
        window.addEventListener('resize', () => {
          myChart.resize()
        })
        let that = this;

        let option = {
          title: {
            text: `${((that.activityData.activityMemberCount/that.activityData.memberCount)*100).toFixed(2)}%`,
            subtext: "平均参与度",
            left: "center",
            top: "42%",
            textStyle: {
              fontSize: 18,
              color: "#333"
            },
            subtextStyle: {
              fontSize: 13,
              color: "#333"
            }
          },
          tooltip: {
            trigger: 'item'
          },
          series: [{
            // name: '平均参与度',
            type: 'pie',
            left: '0%',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter: `${((that.activityData.activityMemberCount/that.activityData.memberCount)*100).toFixed(2)}%`,
            },
            labelLine: {
              show: false
            },
            data: [{
                value: that.activityData.activityMemberCount || 0,
                name: `参与人数`,
                itemStyle: {
                  color: '#FFB822'
                },
              },
              {
                value: (that.activityData.memberCount - that.activityData.activityMemberCount) || 0,
                name: `未参与`,
                itemStyle: {
                  color: '#508EF9'
                },
              },
            ]
          }]
        };
        myChart.setOption(option);
      },

    },
    mounted() {
      this.$nextTick(() => {
        this.getActivityData();
        // this.initEchart();
      })
    },
  }
</script>

<style lang="less" scoped>
  .child-data {
    padding: 21px 0px 50px;


    .avg-join,
    .data-list {
      border: 1px solid #F2F2F2;
      border-radius: 8px;
    }

    #avg-join-pie {
      width: 200px;
      height: 190px;
    }

    .data-list {
      padding: 39px 30px;
      margin-left: 24px;
      flex-grow: 1;
      line-height: 1;
      text-align: center;

      .data-list-li {
        flex: 0 1 110px;
        color: #689CFC;

        &:nth-child(2) {
          color: #4EDDCB;
        }

        &:nth-child(3) {
          color: #F6B926;
        }

        &:nth-child(4) {
          color: #6CDD66;
        }

        &:nth-child(5) {
          color: #CF90E6;
        }
      }

      .data-icon {
        width: 50px;
        height: 50px;
        margin-bottom: 15px;
      }

      .data-num {
        font-size: 25px;
        font-weight: 500;
      }

      .unit {
        font-size: 13px;
        margin-left: 3px;
      }

      .reply {
        font-size: 17px;
      }

      .data-name {
        margin-top: 12px;
        font-size: 12px;
        color: #555;
      }

    }

    .child-data-list {
      margin-top: 24px;
      flex-wrap: wrap;

      .list-item {
        margin-right: 24px;
        width: 293px;
        border: 1px solid #F2F2F2;
        border-radius: 8px;
        margin-bottom: 30px;


        &:hover {
          transition: .5s all;
          box-shadow: 0px 2px 10px 0px #6666661c;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        .cover-wrapper {
          position: relative;

          .default-tilel {
            font-family: 'ktf';
            position: absolute;
            color: #fff;
            font-size: 34px;
            top: 35%;
            left: 40%;
            z-index: 99;
          }
        }

        .item-img {
          width: 100%;
          height: 154px;
        }

        .item-name {
          margin-top: 16px;
          padding: 0 27px;
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          min-height: 50px;
        }

        .info {
          line-height: 1;
          padding: 16px 27px 10px;
          font-size: 12px;
          color: #999;


          >p {
            margin-bottom: 18px;
          }
        }
      }
    }

    @media screen and (min-width :1250px) {
      padding: 25px 0px 33px;

      #avg-join-pie {
        width: 243px;
        height: 230px;
      }

      .data-list {
        padding: 48px 40px;
        margin-left: 29px;

        .data-list-li {
          flex: 0 1 135px;
        }

        .data-icon {
          width: 60px;
          height: 60px;
          margin-bottom: 20px;
        }

        .data-num {
          font-size: 30px;
        }

        .unit {
          font-size: 16px;
          margin-left: 5px;
        }

        .reply {
          font-size: 20px;
        }

        .data-name {
          margin-top: 18px;
          font-size: 15px;
        }

      }

      .child-data-list {
        margin-top: 29px;

        .list-item {
          margin-right: 29px;
          width: 356px;
          margin-bottom: 30px;

          .item-img {
            height: 187px;
          }

          .cover-wrapper {
            position: relative;

            .default-tilel {
              font-size: 38px;
              top: 38%;
              left: 40%;
            }
          }

          .item-name {
            margin-top: 20px;
            padding: 0 34px;
            font-size: 16px;
            line-height: 28px;
            min-height: 54px;
          }

          .info {
            padding: 20px 35px 12px;
            font-size: 14px;
            color: #999;


            >p {
              margin-bottom: 22px;
            }
          }
        }
      }
    }

  }
</style>