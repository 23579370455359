<template>
  <div class="child-activity-member">
    <div class="team-wrapper">
      <div class="period-select" v-show="pages.total > 0">
        <el-input size="mini" v-model="search" placeholder="按学校、名称" suffix-icon="el-icon-search" style="max-width:200px"
          @input="filterMember">
        </el-input>
      </div>
      <ul class="expert-list">
        <li v-for="(item, index) in memberList" :key="index" class="flex-layout">
          <img alt="专家头像" :src="downloadURL + item.avatar" :onerror="$store.state.user.defaultAvatar"
            class="expert-avatar" />
          <div class="expert-info">
            <p class="expert-name">{{item.name}} <span class="expert-title">{{item.title || ''}}</span> </p>
            <p class="expert-introduce">{{item.schoolName || '无'}}</p>
            <p class="expert-introduce">{{item.introduce || '暂无简介'}}</p>
          </div>
        </li>
      </ul>
      <Empty :show="pages.total == 0"></Empty>
      <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
        :limit.sync="pages.pageSize" :pageSizes="[5,10,15]" @pagination="handlePageChange">
      </pagination>
    </div>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        // activeTab: "", //当前选中项目ID
        search: "", //二级项目ID
        memberList: [], // 活动成员列表
        periodList: [], //期列表
        teacherLists: [], //其他专家列表
        pages: { //分页
          currentPage: 1,
          pageSize: 5,
          total: 0,
        },
      };
    },
    watch: {},
    computed: {
      ...mapState({
        currentProject: state => state.user.currentProject,
      })
    },
    methods: {
      //改变展示条数
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        //  console.log(this.pages);
        // this.getOtherTeacher();
        this.getActivityMember();
      },
      filterMember(val) {
        this.getActivityMember();
      },
      async getActivityMember() {
        let params = {
          id: this.$route.params.id,
          page: this.pages.currentPage,
          limit: this.pages.pageSize,
        }
        params.search = this.search ? this.search : null;
        let resData = await this.$Api.Activity.getActivityMember(params);
        // console.log(resData);
        this.memberList = resData.data.records
        this.pages.total = resData.data.total;
        this.$emit('memberTotal', this.pages.total)
      }
    },
    mounted() {
      // this.getSecondList();
      this.getActivityMember();
    }
  };
</script>


<style lang="less" scoped>
  .child-activity-member {
    padding: 25px 34px 0px 29px;

    .team-wrapper {
      margin: 0 auto 50px;
      background-color: #fff;
      border-radius: 8px;
    }

    .period-select {
      text-align: right;

      /deep/ .el-input__inner {
        border-radius: 25px;
      }
    }

    .expert-list {
      li {
        border-bottom: 1px solid #F2F2F2;
        padding: 12px 0;
      }

      .expert-avatar {
        flex-shrink: 0;
        width: 58px;
        height: 58px;
        margin-right: 27px;
        border-radius: 50%;
        border: 5px solid rgba(64, 137, 255, 0.15);
      }

      .expert-name {
        margin: 2px 0 5px;
        font-size: 14px;

        .expert-title {
          font-size: 12px;
          color: #666;
          margin-left: 15px;
        }
      }

      .expert-introduce {
        color: #999;
        line-height: 24px;
      }
    }


    .pagination {
      margin: 30px 0;
    }


    @media screen and (min-width:1250px) {
      padding: 30px 41px 0px 35px;

      .expert-list {

        li {
          padding: 15px 0;
        }

        .expert-avatar {
          width: 70px;
          height: 70px;
        }

        .expert-name {
          font-size: 16px;

          .expert-title {
            font-size: 14px;
          }
        }
      }
    }
  }
</style>