<template>
    <div class="child-activity-list">
        <div class="order flex-layout">
            <el-select v-model="isDesc" style="width: 130px;" size="mini" @change="sortList">
                <el-option label="时间顺序排列" :value="0"></el-option>
                <el-option label="时间倒序排列" :value="1"></el-option>
            </el-select>
        </div>
        <ul class="childList">
            <li v-for="(item,index) in childList" :key="index" class="flex-between childList-li">
                <span class="li-name wes"><span style="margin-right:10px">{{index+1}}</span>{{item.name}}</span>
                <span class="li-time">{{item.beginTime}} -- {{item.endTime}}</span>
                <span class="li-state" :class="stateClass[item.status]">{{stateMap[item.status]}}</span>
                <div class="toolbar">
                    <el-button type="text" size="small" @click="openDetail(item)" v-if="currentActivityType">查看
                    </el-button>
                    <el-button type="text" size="small" :disabled="item.status==2" v-if="currentActivityType"
                        @click="openEdit(item)">编辑</el-button>
                    <el-button type="text" size="small" :disabled="item.status==2" v-if="currentActivityType"
                        @click="deleteChildItem(item.id)">删除
                    </el-button>
                    <br>
                    <el-button type="text" size="small" :disabled="item.status==0 || item.status==2"
                        @click="getGoYzJoin(item)">
                        进入活动</el-button>
                    <el-button type="text" size="small" :disabled="item.status==0" @click="getGoYzCollection(item)">作品集
                    </el-button>
                </div>
            </li>
        </ul>
        <Empty :show="pages.total == 0"></Empty>
        <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
            :limit.sync="pages.pageSize" @pagination="handlePageChange">
        </pagination>

        <!-- 添加/编辑子活动弹窗 -->
        <el-dialog :title="`${isEdit?'编辑':'添加'}子活动`" :visible.sync="showAddDialog" width="600px" @closed="handleClose">
            <el-form ref="addChildActivityForm" :model="addChildActivityForm" label-width="120px" :rules="rules">
                <el-form-item label="子活动名称：" prop="name" required>
                    <el-input v-model.trim="addChildActivityForm.name" size="mini" style="width:220px;"
                        placeholder="请输入子活动名称" maxlength="30" show-word-limit></el-input>
                </el-form-item>

                <el-form-item label="时间：" prop="datetimerange" required>
                    <el-date-picker size="mini" v-model="addChildActivityForm.datetimerange" type="datetimerange"
                        start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期" prefix-icon="el-icon-date"
                        value-format="yyyy-MM-dd HH:mm:ss" :editable="false" class="date-picker"
                        :picker-options="pickerOptions" @change="handleTimeChang">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="地址：" prop="studyUrl" required>
                    <el-input v-model.trim="addChildActivityForm.studyUrl" size="mini" style="width:220px;"
                        placeholder="请输入地址" show-word-limit></el-input>
                    <el-button class="createAddress btn" @click="toYzWall">新建</el-button>
                    <div class="tips csp" @click="showTips = !showTips">
                        <i class="el-icon-question"></i>
                        <div style="margin-top: 20px;">
                            <el-collapse-transition>
                                <div v-show="showTips" class="tips-txt">
                                    <p>1.作品墙活动是展示类活动，可应用合作学习墙开展探究式学习、作品欣赏、讨论辩论、活动评比、思维导图等各种活动。<br>
                                        2.添加子活动之前，需先新建一个合作学习墙。<br>
                                        3.点击分享-设置为公开-复制链接。</p>
                                    <img src="@/assets/images/active/tips1.png" alt="" class="tips-img">
                                    <img src="@/assets/images/active/tips2.png" alt="" class="tips-img">
                                </div>
                            </el-collapse-transition>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item class="btns-box" label-width="0px">
                    <el-button class="submit btn" @click="onSubmit">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 查看子活动弹窗 -->
        <el-dialog title="查看子活动" :visible.sync="showDetailDialog" width="600px" destroy-on-close>
            <el-form ref="detailChildActivityForm" label-width="120px">
                <el-form-item label="子活动名称：" prop="name" required>
                    <p>{{selectedChildItem.name}}</p>
                </el-form-item>

                <el-form-item label="时间：" prop="datetimerange" required>
                    <p>{{selectedChildItem.beginTime}} -- {{selectedChildItem.endTime}}</p>
                </el-form-item>

                <el-form-item label="地址：" prop="studyUrl" required>
                    <p>{{selectedChildItem.studyUrl}}</p>
                </el-form-item>

                <el-form-item class="btns-box" label-width="0px">
                    <el-button class="submit btn" @click="showDetailDialog = false">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {
        mapState
    } from "vuex";
    export default {
        props: {
            activityType: Number,
            creatChild: Boolean,
        },
        data() {
            return {
                isDesc: 0,
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 10,
                },
                stateMap: {
                    0: '未开始',
                    1: '进行中',
                    2: '已结束',
                },
                stateClass: {
                    0: '',
                    1: 'ing',
                    2: 'end',
                },
                childList: [],
                showAddDialog: false, //添加弹窗状态
                showDetailDialog: false, //查看详情弹窗状态
                isEdit: false,
                addChildActivityForm: {
                    name: undefined,
                    datetimerange: undefined,
                    studyUrl: undefined,
                    beginTime: undefined,
                    endTime: undefined,
                },
                showTips: false,
                rules: {
                    name: [{
                        required: true,
                        message: "请输入活动名称",
                        trigger: ['blur', 'change']
                    }],
                    datetimerange: [{
                        required: true,
                        message: "请选择活动时间",
                        trigger: ['blur', 'change']
                    }],
                    studyUrl: [{
                        required: true,
                        message: "请输入地址",
                        trigger: ['blur', 'change']
                    }]
                },
                pickerOptions: { //今天以前（不含今天）禁用
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 8.64e7;
                    }
                },
                selectedChildItem: {}, // 当前选中的子活动
                activityInfo: {},
                yzWallUrl: '',
            }
        },
        watch: {
            'activityType': {
                handler(newVal, oldVal) {
                    if (!newVal) return;
                    this.getChildActivity();
                },
                immediate: true,
            },
            'creatChild': {
                handler(newVal, oldVal) {
                    if (!newVal) return;
                    this.showAddDialog = this.creatChild
                },
            }
        },
        computed: {
            ...mapState({
                currentActivityType: state => state.course.currentActivityType,
                role: state => state.user.role
            }),
        },
        methods: {

            handleClose() {
                this.showTips = false;
                this.$emit('open', false);
                this.isEdit = false;
                this.$refs['addChildActivityForm'].resetFields();
            },
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getChildActivity();
            },
            // 活动时间赋值
            handleTimeChang(val, type) {
                console.log(val);
                this.addChildActivityForm.beginTime = val[0];
                this.addChildActivityForm.endTime = val[1];
            },
            sortList(val) {
                this.pages.currentPage = 1
                this.getChildActivity();
            },
            // 获取进入活动地址并跳转
            async getGoYzJoin(item) {
                let resData = await this.$Api.Activity.getGoYzJoin({
                    id: item.id
                })
                console.log(resData);
                let reg = new RegExp("\\#", "g");
                if (!resData.data) return;
                window.open(resData.data.replace(reg, '@'));
            },
            // 获取作品集地址并跳转
            async getGoYzCollection(item) {
                let params = {
                    id: item.id,
                    activityId: this.$route.params.id,
                    type: this.currentActivityType // 0 我参与的 1 我创建的
                }
                let resData = await this.$Api.Activity.getGoYzCollection(params)
                console.log(resData);
                if (!resData.data) return;
                window.open(resData.data);
            },
            // 跳转学习墙（新建）
            toYzWall() {
                if (!this.yzWallUrl) return;
                window.open(this.yzWallUrl);
            },
            // 获取学习墙地址
            async getGoYzWallUrl() {
                let resData = await this.$Api.Activity.getGoYzWallUrl()
                // console.log(resData.data);
                let reg = new RegExp("\\#", "g");
                // console.log(resData.data.replace(reg,'@'))
                this.yzWallUrl = resData.data.replace(reg, '@')
            },
            // 查看子活动详情
            openDetail(item) {
                this.selectedChildItem = item;
                this.showDetailDialog = true;
            },
            // 编辑子活动详情
            openEdit(item) {
                this.isEdit = true;
                // this.addChildActivityForm = item;
                this.showAddDialog = true;
                this.$nextTick(() => {
                    for (const key in this.addChildActivityForm) {
                        this.addChildActivityForm[key] = item[key]
                    }
                    this.$set(this.addChildActivityForm, 'datetimerange', [item.beginTime, item.endTime])
                    this.$set(this.addChildActivityForm, 'id', item.id)
                })
            },
            // 删除子活动
            deleteChildItem(id) {
                // console.log(id);
                this.$confirm('是否删除该活动?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // let resData = this.$Api.Course.commentDel(item.id)
                    // this.$Api.Course.activityDel(id).then((resData) => {
                    this.$Api.Activity.deleteStudyWallChildActivity(id).then((resData) => {
                        // console.log(resData)
                        if (resData.code === 200) {
                            this.$message.success('删除成功');
                            this.getChildActivity();
                        } else {
                            this.$message.error(resData.msg);
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            // 获取活动数据
            async getActivityInfo() {
                let resData = await this.$Api.Activity.getActivityDetail(this.$route.params.id)
                // console.log('活动信息', resData);
                this.activityInfo = resData.data
                let that = this;
                this.$nextTick(() => {
                    this.pickerOptions = {
                        disabledDate(time) {
                            let beginTime = that.dayjs(that.activityInfo.activityBeginTime).valueOf()
                            let endTime = that.dayjs(that.activityInfo.activityEndTime).valueOf()
                            return time.getTime() < beginTime || time.getTime() > endTime;
                        }
                    }
                })
            },
            // 获取子活动列表
            async getChildActivity() {
                if (!this.activityType) return;
                let params = {
                    id: this.$route.params.id,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    activityType: this.activityType,
                    isDesc: this.isDesc
                }
                let resData = await this.$Api.Activity.getChildActivity(params);
                // console.log('子活动列表', resData.data.records)
                if (!resData.data.records) return;
                this.childList = resData.data.records;
                this.pages.total = resData.data.total;
                this.addChildActivityForm.activityId = Number(this.$route.params.id);
            },
            // 验证并提交表单
            onSubmit() {
                console.log(this.addChildActivityForm);
                this.$refs['addChildActivityForm'].validate((valid) => {
                    if (!valid) {
                        this.$message.error('请正确填写信息')
                        return;
                    }
                    // 校验参数 
                    let url = this.addChildActivityForm.studyUrl;
                    let reg = RegExp(/tenant_id=21&appname=ssyz/)
                    console.log(reg.test(url))
                    if(reg.test(url)){
                        this.commitForm();
                    }else{
                        let studyUrl = url.split('#')[0]+'?tenant_id=21&appname=ssyz#'+url.split('#')[1];
                        this.$set(this.addChildActivityForm, 'studyUrl', studyUrl);
                        console.log('处理后的studyUrl:',this.addChildActivityForm.studyUrl);
                        this.commitForm();
                    }
                    
                })
            },
            // 创建学习墙子活动
            async commitForm() {
                // this.$delete(this.addChildActivityForm,'datetimerange');
                let resData
                if (this.isEdit) {
                    resData = await this.$Api.Activity.editStudyWallChildActivity(this.addChildActivityForm);
                }
                if (!this.isEdit) {
                    resData = await this.$Api.Activity.createStudyWallChildActivity(this.addChildActivityForm);
                }
                console.log(resData);
                if (resData.data.code === 200 || resData.code == 200) {
                    this.$message.success(`${this.isEdit?'编辑':'添加'}子活动成功`)
                    this.showAddDialog = false;
                    this.getChildActivity();

                } else {
                    this.$message.error(`${this.isEdit?'编辑':'添加'}活动失败 ${resData.data.msg}`)
                }
            }
        },
        // mounted() {
        //   this.getActivityInfo();
        //   this.getGoYzWallUrl();
        // },
        activated() {
            this.getActivityInfo();
            this.getChildActivity();
            this.getGoYzWallUrl();
          
        },
    }
</script>

<style lang="less" scoped>
    .child-activity-list {
        padding: 21px 27px 50px;

        .order {
            justify-content: flex-end;

        }

        .childList {
            margin-bottom: 50px;

            .childList-li {
                margin-top: 21px;
                padding: 0 30px;
                line-height: 78px;
                background-color: #FBFAFA;
                box-sizing: border-box;
            }

            .li-name {
                flex: 0 1 300px;
            }

            .li-time {
                font-size: 12px;
                color: #999;
            }

            .li-state {
                font-size: 12px;
                padding: 0 8px;
                line-height: 15px;
                border-radius: 25px;
                border: 1px solid #999999;
                color: #999999;
                align-self: center;

                &.ing {
                    border: none;
                    color: #508EF9;
                    padding: 0 9px;
                    line-height: 17px;
                    background: rgba(80, 142, 249, 0.15);
                }

                &.end {
                    border: 1px solid #CBCBCB;
                    color: #CBCBCB;
                }
            }

            .toolbar {
                line-height: initial;
                padding: 7px 0;
            }
        }

        @media screen and (min-width :1250px) {
            padding: 25px 33px 70px;

            .childList {
                margin-bottom: 50px;

                .childList-li {
                    margin-top: 25px;
                    padding: 0 40px;
                    line-height: 94px;
                    font-size: 15px;
                }

                .li-name {
                    flex: 0 1 390px;
                }

                .li-time {
                    font-size: 13px;
                    color: #999;
                }

                .li-state {
                    font-size: 13px;
                    padding: 0 10px;
                    line-height: 18px;

                    &.ing {
                        padding: 0 11px;
                        line-height: 19px;
                    }
                }

                .toolbar {
                    line-height: initial;
                    padding: 15px 0;
                    font-size: 13px;
                }
            }

        }
    }
</style>